import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ArrowDownIcon, CheckIcon, PhoneIcon } from '@heroicons/react/solid';
import PhotoGallery from '../../components/PhotoGallery';
import ContactUs from '../../components/ContactUs';
import SEO from '../../components/SEO';

const tiers = [
  {
    name: 'Standard Party Package',
    price: 275,
    description: 'Covers 15 Guests ($15 per additional guest)',
    features: [
      '1 Hour Introduction Karate Class',
      'Gladiator Challenge',
      'Giant Obstacle Course',
      '30 Minutes for Food and Celebrations in Lobby',
    ],
  },
  {
    name: 'Pizza Party Package',
    price: 350,
    description: 'Covers 15 Guests ($20 per additional guest)',
    features: [
      'All Standard Party Features',
      'Organization of Food and Celebrations',
      'Table Cloth, Plates, Napkins',
      'Pizza, Capri Sun, Cupcakes, Candles & Clean Up',
    ],
  },
];

export default function BirthdayPartiesPage() {
  const data = useStaticQuery(graphql`
  query {
    halloween: file(relativePath: { eq: "halloween.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: {cropFocus: CENTER }, )
      }
    }
    fighting: file(relativePath: { eq: "fighting-stance.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: {cropFocus: NORTH }, )
      }
    }
    gallery: sanityGallery(name: {eq: "Homepage Gallery"}) {
      photos {
        id
        image {
          asset {
            gatsbyImageData( placeholder: BLURRED)
          }
        }
        name
        order
        tags {
          title
        }
        caption
      }
      name
      caption
    }
  }
`);
  return (
    <>
      <SEO
        title="Birthday Parties"
        description="  Invite your friends to try out a self-defense class at a Yuan Yen Do
        Birthday Party!"
        image={data.halloween}
      />
      <section className="relative z-20 xl:container xl:mx-auto">
        <div className="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48 lg:text-left">
          <div className="relative w-full h-64 mb-8 lg:hidden sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
            <GatsbyImage
              className="absolute inset-0 w-full h-full filter drop-shadow-lg"
              imgClassName="object-contain object-left"
              image={data.halloween.childImageSharp.gatsbyImageData}
              alt="Yuan Yen Do students with trophies"
            />
          </div>
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl font-extrabold tracking-tight text-center text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">Birthday Parties </span>
              {' '}
              <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600 xl:inline">fit for a Ninja</span>
            </h1>
            <p className="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Invite your friends to try out a self-defense class at a Yuan Yen Do
              Birthday Party!
            </p>
            <p className="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              You and your friends will participate in an hour-long class
              learning to test your new skills in our Gladiator Challenge, Punch and Kick
              Drills, and a Giant Ninja Obstacle Course! After class,
              there is a 30-minute party for cake and presents!
            </p>
            <div className="mt-10 sm:flex sm:justify-center">
              <div className="rounded-md shadow">
                <AnchorLink
                  to="/events/tournaments#tournament-info"
                  title="Tournament Info"
                  className="flex items-center justify-center px-4 py-3 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary bg-opacity-90 hover:bg-opacity-100 sm:px-8"
                >
                  <ArrowDownIcon className="w-5 h-5 pr-2" />
                  Learn More
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-full h-64 lg:block sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <GatsbyImage
            className="absolute inset-0 w-full h-full filter drop-shadow-lg"
            imgClassName="object-contain object-left"
            image={data.halloween.childImageSharp.gatsbyImageData}
            alt="Yuan Yen Do students with trophies"
          />
        </div>
      </section>
      <section className="z-0 bg-cover shadow-inner bg-lines filter">
        <div className="pt-12 sm:pt-16 ">
          <div className="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
              <h2 className="text-4xl font-extrabold tracking-tight text-center uppercase sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl text-secondary">Birthday Party Pricing</h2>

              <p className="text-xl text-gray-300">
                Talk to your center leader about our different
                party options for your next birthday!
              </p>
            </div>
          </div>
        </div>
        <div className="pb-12 mt-8 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="relative">
            <div className="absolute inset-0 bg-cover bg-lines h-3/4" />
            <div className="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                {tiers.map((tier) => (
                  <div key={tier.name} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                    <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                      <div>
                        <h3
                          className="inline-flex px-4 py-1 text-sm font-semibold tracking-wide uppercase bg-red-100 rounded-full text-secondary"
                          id="tier-standard"
                        >
                          {tier.name}
                        </h3>
                      </div>
                      <div className="flex items-baseline mt-4 text-6xl font-extrabold">
                        $
                        {tier.price}

                      </div>
                      <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                    </div>
                    <div className="flex flex-col justify-between flex-1 px-6 pt-6 pb-8 space-y-6 bg-gray-50 sm:p-10 sm:pt-6">
                      <ul className="space-y-4">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0">
                              <CheckIcon className="w-6 h-6 text-green-500" aria-hidden="true" />
                            </div>
                            <p className="ml-3 text-base text-gray-700">{feature}</p>
                          </li>
                        ))}
                      </ul>
                      <div className="max-w-sm mx-auto mt-10 ">
                        <AnchorLink
                          to="/events/birthday-parties#contact-us"
                          className="flex items-center justify-center py-3 pl-2 pr-4 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary bg-opacity-90 hover:bg-opacity-100 sm:px-8"
                        >
                          <PhoneIcon className="w-6 h-6 pr-2" />
                          Contact Us
                        </AnchorLink>
                      </div>
                    </div>

                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="relative px-4 mx-auto mt-4 max-w-7xl sm:px-6 lg:px-8 lg:mt-5">
            <div className="max-w-md mx-auto lg:max-w-5xl">
              <div className="px-6 py-8 bg-gray-100 rounded-lg sm:p-10 lg:flex lg:items-center">
                <div className="flex-1">
                  <div>
                    <h3 className="inline-flex px-4 py-1 text-sm font-semibold tracking-wide text-gray-800 uppercase bg-white rounded-full">
                      Photographer
                    </h3>
                  </div>
                  <div className="mt-4 text-lg text-gray-600">
                    Let our photographer capture every moment on this special day for
                    {' '}
                    <span className="font-semibold text-gray-900">$75</span>
                    .
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <PhotoGallery title="Ninja Birthday Parties" caption="90 minutes of non-stop fun!" gallery={data.gallery} />

      <ContactUs />
    </>
  );
}
